<input type='radio'
       class='btn-check'
       name='value'
       id='radio-btn-{{option.value}}'
       autocomplete='off'
       [value]='option.value'
       [(ngModel)]='selectedOption.value'
       (change)='onChange.emit(selectedOption.value)'
       [disabled]='option.disabled'
><label class='btn radio-label' for='radio-btn-{{option.value}}'>{{option.label}}</label>

