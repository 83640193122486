import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Manifestation } from '../manifestation/manifestation';
import { IllnessScriptModel } from '../illness-script/illness-script';
import { SearchResult, RecentSearch } from './search';

@Injectable()
export class SearchService {
  private readonly STORAGE_KEY = 'recentSearches';

  constructor(private httpClient: HttpClient) {}

  search(searchText: string): Observable<SearchResult[]> {
    const manifestations = this.httpClient.get<Manifestation[]>(
      environment.apiBaseUrl + '/manifestations'
    );

    const illnessscripts = this.httpClient.get<IllnessScriptModel[]>(
      environment.apiBaseUrl + '/illness-scripts/'
    );

    const filteredManifestations = manifestations.pipe(
      map((manifestations) =>
        manifestations.filter((m) =>
          m.displayName.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );

    const filteredIllnessScripts = illnessscripts.pipe(
      map((illnessScripts) =>
        illnessScripts.filter((i) =>
          i.name.toLowerCase().includes(searchText.toLowerCase())
        )
      )
    );

    return combineLatest([filteredManifestations, filteredIllnessScripts]).pipe(
      map(([manifestations, illnessScripts]) => {
        const searchResults: SearchResult[] = [];

        searchResults.push(
          ...manifestations.map((m) => ({
            id: m.id,
            type: 'Problems',
            title: m.displayName.trim(),
          }))
        );

        searchResults.push(
          ...illnessScripts.map((i) => ({
            id: i.id,
            type: 'Illness Scripts',
            title: i.name.trim(),
          }))
        );

        return searchResults;
      })
    );
  }

  getRecentSearches(): RecentSearch[] {
    const searches = localStorage.getItem(this.STORAGE_KEY);
    return searches ? JSON.parse(searches) : [];
  }

  saveRecentSearch(search: string): void {
    if (!search) {
      return;
    }

    const searches: RecentSearch[] = this.getRecentSearches();
    const existingSearch = searches.find((s) => s.title === search);
    if (existingSearch) {
      return;
    }

    const newSearches: RecentSearch = {
      title: search,
    };
    searches.push(newSearches);
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(searches));
  }

  clearRecentSearches(): void {
    localStorage.removeItem(this.STORAGE_KEY);
  }
}
