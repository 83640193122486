import { Component, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { SearchContainerComponent } from './components/search-container/search-container.component';
import { UnsubscribeOnDestroyAdapter } from 'src/lib/base/unsubscribe.adapter';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit, OnDestroy
{
  @ViewChild(SearchContainerComponent)
  searchContainer!: SearchContainerComponent;

  @Input() Mobile = false;

  showModal = false;
  searchBarText = '';
  private routerSubscription!: Subscription;

  constructor(private router: Router) {
    super();
  }

  ngOnInit() {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showModal = false;
      }
    });
  }

  closeModal() {
    this.showModal = false;
  }

  openModal() {
    this.showModal = true;
  }

  triggerSearch(event: Event) {
    this.showModal = true;

    if (this.searchBarText) {
      setTimeout(() => {
        if (this.searchContainer) {
          this.searchContainer.onSearch(event);
        }
      }, 200);
    }
  }
}
