<div class="header">
  <button class="mh-icon " type="button" (click)="redirectToHomePage()">
    <img src="../../../assets/mh-logo.svg" alt="Mc Graw Hill logo">
    <p class="title">Clinical Reasoning</p>
  </button>
  <div class="tabs d-none d-lg-flex">
    <button type="button" class="home" (click)="redirectToHomePage()">
      <img src="../../../../../assets/home-icon.svg" alt="home icon" class="icon">
      <p class="home-text">Home</p>
    </button>

    <button type="button" class="my-account" (click)="openDropdown = !openDropdown">
      <img src="../../../../../assets/my-account-icon.svg" alt="my account icon" class="icon">
      <p class="home-text">My Account</p>
      <img src="../../../../../assets/chevron-up-white.svg" alt="arrow down icon" class="chevron-icon"
        [class.rotate]="!openDropdown">
    </button>
  </div>
  <div class="account-options d-none d-lg-flex" *ngIf="openDropdown" [class.dropdown-animation]="!openDropdown">
    <p class="options-title">My Account</p>
    <button class="options" (click)="openAccountDetails()">My Account Details</button>
    <button class="options" (click)="signOut()">Sign Out</button>
  </div>
  <app-search class="search-bar d-none d-lg-flex" *ngIf=" isSearchComponentEnabled$ | async" />
  <div class="menu d-sm-inline d-lg-none">
    <div class="d-flex align-items-center">
      <app-search [Mobile]="true" *ngIf="isSearchComponentEnabled$ | async" />
      <button class="hamburger-button " type="button" (click)='showModal = true'>
        <img src="../../../../../assets/hamburguer.svg" alt="hamburger menu icon">
      </button>
    </div>
  </div>
</div>

<div class="modal-backdrop" *ngIf="openDropdown" (click)="openDropdown = false"></div>

<app-menu-drawer-mobile icon="/assets/close-white.svg" (close)='showModal = false' *ngIf='showModal' />