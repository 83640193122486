import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IllnessScriptModel } from './illness-script';

@Injectable()
export class IllnessScriptService {
  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<IllnessScriptModel[]> {
    const data = this.httpClient.get<IllnessScriptModel[]>(
      environment.apiBaseUrl + '/illness-scripts/'
    );
    return data;
  }

  get(ids: string[]): Observable<IllnessScriptModel[]> {
    const data = this.httpClient.get<IllnessScriptModel[]>(
      environment.apiBaseUrl + '/illness-scripts/',
      {
        params: {
          id: ids,
        },
      }
    );
    return data;
  }
}
