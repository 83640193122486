<div *ngIf="!Mobile" class="search">
    <form class="form-inline my-2 my-lg-0" (submit)="triggerSearch($event)">
        <label for="search-input" class="sr-only">Search problems and illness scripts</label>
        <input id="search-input" (click)='openModal()' type="search" class="search-input"
            placeholder="Search problems and illness scripts" name="search" [(ngModel)]="searchBarText">
        <button class="search-icon" type="submit">
            <img src='/assets/search.svg' alt='search icon'>
        </button>
    </form>
</div>
<button *ngIf="Mobile" class="mobile-search-icon" type="button" (click)="showModal = true">
    <img src='/assets/search.svg' alt='search icon'>
</button>

<app-modal *ngIf='showModal' (close)='closeModal()' headerText='Search' icon='/assets/close.svg'>
    <app-search-container />
</app-modal>