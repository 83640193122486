import { CommonModule, } from '@angular/common';
import { Component, Input, } from '@angular/core';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-illness-accordion',
  standalone: true,
  imports: [CommonModule, NgbAccordionModule],
  templateUrl: './illness-accordion.component.html',
  styleUrls: ['./illness-accordion.component.scss']
})
export class IllnessAccordionComponent {
  @Input()
  title = 'Accordion';

  @Input()
  openedByDefault = false;
}
