import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.scss'],
})
export class MobileHeaderComponent {
  constructor(private locationService: Location, private router: Router) {}

  @Input() title = 'Title';
  @Input() icon1: string | undefined = '';
  @Input() icon2: string | undefined = '';
  @Input() problemStatements: string[] = [];

  @Input() type: 'primary' | 'secondary' = 'primary';

  showProblemStatementHistory = false;
  showModal = false;

  goBack() {
    this.locationService.back();
  }

  public get classes(): string[] {
    return ['header', `header--${this.type}`];
  }

  redirectToAlgorithm() {
    this.router.navigateByUrl(`${this.router.url}/algorithm`);
  }

  getTitle() {
    if (this.type === 'secondary') {
      return 'Clinical Reasoning';
    }
    return this.title;
  }

  get currentProblemStatement(): string | undefined {
    return this.problemStatements[this.problemStatements.length - 1];
  }

  get chevronIcon() {
    return this.showProblemStatementHistory
      ? 'assets/chevron-up-white.svg'
      : 'assets/chevron-down-white.svg';
  }
}
