import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterGenericComponent } from './filter-generic.component';
import { FilterButtonsComponent } from './filter-buttons/filter-buttons.component';

@NgModule({
  declarations: [FilterGenericComponent],
  imports: [CommonModule, FormsModule, FilterButtonsComponent],
  exports: [FilterGenericComponent],
})
export class FilterGenericModule {}
