import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { toHTML } from '@portabletext/to-html';
import { PortableTextBlock } from '@portabletext/types';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-rich-text',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.scss']
})
export class RichTextComponent {
  @Input() content: PortableTextBlock[] = [];

  constructor(protected sanitizer: DomSanitizer) {
  }

  convert() {
    return this.sanitizer.bypassSecurityTrustHtml(toHTML(this.content));
  }
}
