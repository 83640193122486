import { Component, EventEmitter, Input, Output } from '@angular/core';
import { illnessTypes } from './illness.types';
import { LinkComponent } from '../link/link.component';
import { ModalComponent } from '../modal/modal.component';
import { TitleComponent } from '../title/title.component';
import { TextboxModule } from '../textbox/textbox.module';
import { IllnessAccordionComponent } from '../illness-accordion/illness-accordion.component';
import { CommonModule } from '@angular/common';
import { IllnessScriptModel } from 'src/lib/illness-script/illness-script';
import { RichTextComponent } from '../rich-text/rich-text.component';
import { PortableTextBlock } from '@portabletext/types';
import { UnsubscribeOnDestroyAdapter } from 'src/lib/base/unsubscribe.adapter';

@Component({
  selector: 'app-single-illness',
  standalone: true,
  templateUrl: './single-illness.component.html',
  styleUrls: ['./single-illness.component.scss'],
  imports: [
    LinkComponent,
    ModalComponent,
    TitleComponent,
    TextboxModule,
    IllnessAccordionComponent,
    RichTextComponent,
    CommonModule,
  ],
})
export class SingleIllnessComponent extends UnsubscribeOnDestroyAdapter {
  @Input() illness: IllnessScriptModel | null = null;
  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  protected readonly illnessTypes = illnessTypes;

  closeModal() {
    this.onClose.emit();
  }

  getRichText(illnessScriptModel: any, type: string) {
    return illnessScriptModel[type] as PortableTextBlock[];
  }
}
