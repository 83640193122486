import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Options } from './filter-buttons/filter-buttons.types';

@Component({
  selector: 'app-filter',
  templateUrl: './filter-generic.component.html',
  styleUrls: ['./filter-generic.component.scss'],
})
export class FilterGenericComponent {
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() option: Options = { disabled: false, label: '', value: '' };
  @Input() selectedOption = {
    label: 'All Problems',
    value: 'allProblems',
    disabled: false,
  };

  @Input() labelMappings: Options[] = [
    {
      label: 'All Problems',
      value: 'allProblems',
      disabled: false,
    },
    {
      value: 'sign_symptom',
      label: `Signs & Symptoms`,
      disabled: false,
    },
  ];
}
