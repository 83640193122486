import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-checkbox-button',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './checkbox-button.component.html',
  styleUrls: ['./checkbox-button.component.scss'],
})
export class CheckboxButtonComponent {
  @Input() label = '';
  @Input() value = '';
  @Input() count = 0;
  @Input() checked = false;
  @Output() onChangeCheckbox = new EventEmitter<Event>();

  onChange(event: Event) {
    this.onChangeCheckbox.emit(event);
  }
}
