import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { Input } from '@angular/core';

@Component({
  selector: 'app-list-item',
  standalone: true,
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  imports: [CommonModule],
})
export class ListItemComponent {
  @Input()
  title = 'List Item';

  @Input()
  icon = '';

  @Input()
  iconPos: 'left' | 'right' = 'left';

  @Output()
  onClick = new EventEmitter<Event>();
}
