import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Options } from './filter-buttons.types';

@Component({
  selector: 'app-filter-buttons',
  standalone: true,
  imports: [FormsModule, CommonModule],
  templateUrl: './filter-buttons.component.html',
  styleUrls: ['./filter-buttons.component.scss'],
})
export class FilterButtonsComponent {
  @Input() option: Options = { value: '', label: '', disabled: false };
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() selectedOption: Options = { label: '', value: '', disabled: false };

  ngOnInit() {
    this.onChange.emit(this.selectedOption.value);
  }
}
export { Options };

