import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-search-input',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent {
  @Input()
  searchText = '';
  @Input() placeholder = '';
  @Output() onSubmitSearch = new EventEmitter<Event>();
  @Output() onSearchTextChange = new EventEmitter<Event>();

  onSubmit(event: Event) {
    this.onSubmitSearch.emit(event);
  }

  onSearchTextChangeHandler(event: Event) {
    this.onSearchTextChange.emit(event);
  }
}
