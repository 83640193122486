<button type="button" class="option-button" (click)="onClick.emit($event)">
  <span class='list-item'>
    <div [class.reverse]="iconPos === 'right'" class="d-flex align-items-center">
      <img *ngIf="icon" class="item-icon" src={{icon}} alt='item icon'>
      <p> {{title}} </p>
    </div>
    <span class="icons">
      <ng-content></ng-content>
      <img class="arrow" src='../../../assets/chevron-right.svg' alt='arrow-right'>
    </span>
  </span>
</button>