<div ngbAccordion class="accordion" data-testId="accordion">
	<div ngbAccordionItem [collapsed]='!openedByDefault'>
		<h3 ngbAccordionHeader data-testId="accordion-header">
			<button ngbAccordionButton class="accordion-button" data-testId="accordion-button">
          {{ title }}
      </button>
		</h3>
		<div ngbAccordionCollapse>
			<div ngbAccordionBody data-testId="accordion-content">
        <ng-content></ng-content>
			</div>
		</div>
	</div>
</div>
