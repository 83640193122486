<div class="header-wrapper" [ngClass]="classes">
  <img alt="go back" *ngIf="icon1" [src]="icon1" (click)="goBack()" class="icon1" />

  <h1 data-testid="header-title">
    {{getTitle()}}
  </h1>

  <img alt="show menu icon" *ngIf="icon2" [src]="icon2" class="icon2" (click)='showModal = true' />
</div>
<app-floating-button icon="/assets/algorithm-icon.svg" (onClick)='redirectToAlgorithm()' />
<app-menu-drawer-mobile icon="/assets/close-white.svg" (close)='showModal = false' *ngIf='showModal' />