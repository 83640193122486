<div class="modal-backdrop" (click)="closeModal()"></div>
<div [style.left]="calcLeft" class="modal d-block {{transitionClass}}">
  <div [style.width]="calcWidth" class="modal-dialog modal-fullscreen modal-dialog-scrollable modal-size">
    <div class="modal-content">
      <div class="modal-header" [ngClass]="{'modal-header-small': headerStyle === 'small'}">
          <h1 *ngIf="headerText" class="modal-header-text mx-3 pt-2" [ngClass]="{'modal-header-text-small': headerStyle === 'small'}">{{headerText}}</h1>
          <button id='close-btn' type='button' #closeBtn aria-label='Close' [ngClass]='classes'
          (click)='closeModal()'><img alt='close icon' [src]='icon' /></button>
      </div>
      <div *ngIf="headerStyle === 'small'" class="modal-header-underline" aria-hidden="true"></div>
      <div class="modal-body" [style.padding]="padding">
        <h1 *ngIf='title' class="modal-title">
          <img *ngIf='titleIcon' [src]="titleIcon" alt='modal title icon' />
          {{title}}
        </h1>
        <ng-content></ng-content>
      </div>
      <div class="modal-footer"><ng-content select="[footer]" [ngTemplateOutlet]="content.templateRef"></ng-content>
      </div>
    </div>
  </div>
</div>