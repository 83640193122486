<ng-container *ngIf='illness'>
  <app-modal padding="2rem 3.75rem 1rem 3.75rem" width="864px" (close)="closeModal()" [headerText]="'Illness Script'" [icon]="'../../assets/close.svg'" [headerStyle]="'small'">
    <div class="header">
      <h2 id="title" >{{illness.illnessName}}</h2>
      <div class="labels">
        <img src="assets/medical.svg">
        <span [style.color]="'#DA1921'">Emergent</span>
        <img [style.marginLeft]="'8px'" src="assets/alert-triangle.svg">
        <span [style.color]="'#B55638'">Must Not Miss</span>
      </div>
    </div>
    <div class="textbox-wrapper">
      <app-textbox [text]="illness.overview" />
    </div>
      <app-illness-accordion
      *ngFor='let accordion of illnessTypes; let i = index'
      [title]='accordion.title'
      [openedByDefault]='i === 0'
    >
    <app-rich-text [content]='getRichText(illness, accordion.type)' />
    </app-illness-accordion>
  </app-modal>
</ng-container>
