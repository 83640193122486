import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UnsubscribeOnDestroyAdapter } from 'src/lib/base/unsubscribe.adapter';
import { SEARCH_COMPONENT } from 'src/lib/feature-flags/feature-flags';
import { FeatureFlagsService } from 'src/lib/feature-flags/feature-flags.service';
import { HeaderService } from 'src/lib/header/header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  extends UnsubscribeOnDestroyAdapter
  implements OnInit
{
  @Output() onClick = new EventEmitter<Event>();
  displayName = '';
  showModal = false;
  openDropdown = false;
  isSearchComponentEnabled$: Observable<boolean> | undefined;

  constructor(
    private headerService: HeaderService,
    private featureFlagsService: FeatureFlagsService,
    private changeDetector: ChangeDetectorRef,
    private router: Router
  ) {
    super();
  }

  ngOnInit() {
    this.subs.add(
      this.headerService.displayName$.subscribe((r) => {
        this.displayName = r;
        this.changeDetector.detectChanges();
      })
    );

    this.featureFlagsService.loadFeatureFlags();
    this.isSearchComponentEnabled$ =
      this.featureFlagsService.isFeatureEnabled(SEARCH_COMPONENT);
  }

  redirectToHomePage() {
    this.router.navigate(['/']);
  }

  openAccountDetails() {
    this.router.navigateByUrl(`/account?redirectUrl=${window.location.href}`);
  }

  signOut() {
    this.router.navigateByUrl(`/mg/auth?redirectUrl=${window.location.href}`);
  }
}
