import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import ButtonComponent from '../button/button.component';
import { UnsubscribeOnDestroyAdapter } from '../../../lib/base/unsubscribe.adapter';
import { timer } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  standalone: true,
  styleUrls: ['./modal.component.scss'],
  imports: [ButtonComponent, CommonModule],
})
export class ModalComponent
  extends UnsubscribeOnDestroyAdapter
  implements AfterViewInit
{
  @ViewChild('closeBtn') closeBtn: ElementRef | undefined;

  @Input() headerText = '';
  @Input() title = '';
  @Input() position: 'left' | 'right' = 'right';
  @Input() icon?: string;
  @Input() titleIcon?: string;
  @Input() width?: string = '570px';
  @Input() padding?: string = '1rem';
  @Input() headerStyle?: 'default' | 'small' = 'default';
  @Output() close = new EventEmitter<boolean>();
  mobileBreak: boolean | undefined = undefined;
  animatedWidth?: string = '';
  animatedLeft?: string = '';

  Breakpoints = Breakpoints;
  currentBreakpoint = '';

  readonly breakpoint$ = this.breakpointObserver.observe([
    '(min-width: 576px)',
  ]);

  ngOnInit(): void {
    this.breakpoint$.subscribe((data) => (this.mobileBreak = !data.matches));

    setTimeout(() => {
      this.animatedWidth = this.width;
      this.animatedLeft = '0';
    }, 50);
  }

  get calcLeft() {
    if (this.mobileBreak) return this.animatedLeft;
    return `calc(100vw - ${this.animatedWidth})`;
  }

  get calcWidth() {
    if (this.mobileBreak) return '100vw';
    return this.width;
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {
    super();
  }

  transitionClass: 'hide' | 'show' = 'hide';

  closeModal() {
    this.closeModalAnimation();
    this.subs.add(
      timer(500).subscribe(() => {
        this.close.emit();
      })
    );

    return false;
  }

  closeModalAnimation() {
    this.transitionClass = 'hide';
  }

  ngAfterViewInit(): void {
    this.subs.add(
      timer(5).subscribe(() => {
        this.closeBtn?.nativeElement.focus();
        this.transitionClass = 'show';
      })
    );

    this.cdr.detectChanges();
  }

  public get classes(): string[] {
    return [
      `btn--${this.position}`,
      `btn--${this.position}${this.headerStyle === 'small' ? '-small' : ''}`,
    ];
  }
}
